<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="projectName" extended>
      <template #extension>
        <u-stepper-progression
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Breadcrumbs  -->
    <v-container
      class="pa-0 d-flex justify-lg-space-between align-center"
      fluid
    >
      <v-col>
        <u-breadcrumbs :items="breadcrumbItems" />
        <h1 class="secondary--text">Project Details</h1>
      </v-col>
      <v-col v-if="!editDetailsFlag" align-self="end" class="text-right">
        <u-button
          class="secondary--text text-capitalize font-weight-bold text-body-1"
          color="gray-6"
          @click="editFields"
        >
          <v-icon class="mr-2" small>fas fa-edit</v-icon>
          Edit
        </u-button>
      </v-col>
    </v-container>

    <v-row align="stretch" class="content my-2">
      <!--  Form  -->
      <v-col cols="12" md="5">
        <v-sheet
          class="
            pa-6
            py-12
            card-border
            fill-height
            d-flex
            flex-column
            justify-center
          "
          outlined
          rounded="lg"
        >
          <v-container
            v-if="!editDetailsFlag"
            class="ma-0 pa-0 fill-height flex-column align-start"
          >
            <label class="label"> Project Name </label>
            <p class="subtitle-1 font-weight-bold mb-6 py-2">
              {{ projectName }}
            </p>
            <label class="label"> Project Description </label>
            <p class="subtitle-1 font-weight-bold mb-6 py-2">
              {{ projectDescription }}
            </p>
          </v-container>
          <u-text-field
            v-if="editDetailsFlag"
            ref="newProjectName"
            v-model="projectName"
            :hide-details="false"
            :rules="[rules.required, rules.noRepeat]"
            label="Project Name"
            large
            placeholder="Eg - JFK Terminal 1 De-congestion"
            required
          />
          <u-text-area
            v-if="editDetailsFlag"
            v-model="projectDescription"
            :rows="8"
            label="Project Description"
            large
            placeholder="Describe your project's details here"
          />
          <u-text-field
            v-if="false"
            v-model="coordinateString"
            class="mb-6"
            disabled
            label="Location"
            large
            placeholder="Search location on the map"
          />
          <v-spacer></v-spacer>
          <label class="label"> Location </label>
          <p class="subtitle-1 font-weight-bold py-2">
            {{ coordinateString }}
          </p>
        </v-sheet>
      </v-col>

      <!--  Map  -->
      <v-col cols="12" md="7">
        <v-sheet
          class="card-border fill-height overflow-hidden"
          outlined
          rounded="lg"
        >
          <u-map
            v-if="editDetailsFlag"
            ref="map"
            :center="mapCenter"
            allow-map-search
            class="fill-height"
            show-navigation-controls
            show-search-marker
            zoom="16"
            @onSearchResult="updateCoords"
          ></u-map>

          <v-img v-else :src="mapImage" class="fill-height" />
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Action Buttons  -->
    <v-row v-if="editDetailsFlag" class="px-4 my-1 gap-2" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="gray-6"
        large
        @click="editDetailsFlag = false"
      >
        Cancel
      </u-button>
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        @click="updateProjectDetails"
      >
        Save
      </u-button>
    </v-row>
    <v-row v-else class="px-4 my-1" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        @click="next"
      >
        Next
      </u-button>
    </v-row>
  </v-container>
</template>

<script>
import {
  UBreadcrumbs,
  UButton,
  UMap,
  UTextArea,
  UTextField,
} from "@/components/base";
import { UProjectTopNav, UStepperProgression } from "@/components/common";
import { getStaticMapImageUrl } from "@/utils";
import { PROJECT_SETUP_STEPPER } from "@/utils/navStepper.data";
import { PROJECT_DETAILS_BREADCRUMBS } from "@/utils/breadcrumbs.data";
import { mapState } from "vuex";

export default {
  name: "ProjectDetails",
  components: {
    UMap,
    UStepperProgression,
    UButton,
    UTextArea,
    UTextField,
    UBreadcrumbs,
    UProjectTopNav,
  },
  mounted() {
    this.initFormFields();
  },
  computed: {
    ...mapState("Project", ["activeProject"]),
    mapCenter() {
      return [
        this.activeProject.coordinates.long,
        this.activeProject.coordinates.lat,
      ];
    },
    projectName: {
      get() {
        return this.editDetailsFlag
          ? this.newProjectName
          : this.activeProject?.name ?? "";
      },
      set(value) {
        this.newProjectName = value;
      },
    },
    projectDescription: {
      get() {
        return this.editDetailsFlag
          ? this.newProjectDescription
          : this.activeProject?.description ?? "";
      },
      set(value) {
        this.newProjectDescription = value;
      },
    },
    coordinateString() {
      const { lat, long } = this.activeProject.coordinates;
      return this.editDetailsFlag
        ? this.newCoordinateString
        : `${lat}, ${long}`;
    },
    mapImage() {
      return getStaticMapImageUrl(
        this.activeProject.coordinates,
        "1280x720",
        16,
        true,
        undefined,
        false,
        false,
      );
    },
  },
  data() {
    return {
      stepper: PROJECT_SETUP_STEPPER.createProject,
      breadcrumbItems: PROJECT_DETAILS_BREADCRUMBS,
      editDetailsFlag: false,
      // Form
      newProjectName: "",
      newProjectDescription: "",
      newSearchCoordinates: {},
      newCoordinateString: "",
      rules: {
        required: (value) => !!value || "Required.",
        noRepeat: (value) => {
          const projectExists = this.$store.state.User.projects.some(
            (project) =>
              project.name === value && project.id !== this.activeProject.id,
          );
          return projectExists ? "Project name already exists." : true;
        },
      },
    };
  },
  methods: {
    // Initialize form fields
    initFormFields() {
      this.projectName = this.activeProject?.name ?? "";
      this.projectDescription = this.activeProject?.description ?? "";
      const { lat, long } = this.activeProject.coordinates;
      this.newSearchCoordinates = { lat, long };
      this.newCoordinateString = `${lat}, ${long}`;
    },

    // Setup edit mode
    editFields() {
      this.editDetailsFlag = true;
      this.initFormFields();
      this.$nextTick(() => {
        const { lat, long } = this.activeProject.coordinates;
        this.$refs.map.geocoder.query(`${lat}, ${long}`);
      });
    },

    // Update the project coordinates based on the search result
    updateCoords(coords) {
      const { lat, lng } = coords;
      this.newSearchCoordinates = { lat, long: lng };
      this.newCoordinateString = `${lat}, ${lng}`;
    },

    // Update the project details
    async updateProjectDetails() {
      if (!this.$refs.newProjectName.validate()) return;
      const params = {
        ...this.activeProject,
        name: this.newProjectName,
        description: this.newProjectDescription,
        location: "",
        coordinates: {
          lat: this.newSearchCoordinates.lat,
          long: this.newSearchCoordinates.long,
        },
      };
      await this.$store.dispatch("Project/updateProject", params);
      this.editDetailsFlag = false;
      this.$store.commit("Project/storeProjectDetails", params);
    },

    next() {
      this.$router.push({
        name: "Video Library",
        params: { project_id: this.activeProject.project_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}

.label {
  font-size: var(--body-2);
  font-weight: bold;
  color: var(--secondary);
}
</style>
